<template>
  <div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      title: "",
      url: "",
    };
  },
};
</script>
